import { OVERSIZE_OUTPUT_MIMETYPE } from "@hex/common";
import { partition } from "lodash";
import { useMemo } from "react";

import { UseCellDataResult } from "../../../output/useCellData";

export function useFilteredOutputs(outputs: UseCellDataResult[]): {
  richOutputs: UseCellDataResult[];
  previewOutputs: UseCellDataResult[];
  errorOutputs: UseCellDataResult[];
} {
  return useMemo(() => getTableOutputs(outputs), [outputs]);
}

export function getTableOutputs(outputs: UseCellDataResult[]): {
  richOutputs: UseCellDataResult[];
  previewOutputs: UseCellDataResult[];
  errorOutputs: UseCellDataResult[];
} {
  const [errorOutputs, nonErrorOutputs] = partition(
    outputs,
    ({ outputFragment }) => outputFragment.outputType === "ERROR",
  );
  const previewOutputs = nonErrorOutputs.filter(
    (output) => output.type === "table",
  );
  const richOutputs = nonErrorOutputs.filter(
    (output) =>
      output.type === "display_table" ||
      output.type === "column_aggregations" ||
      // also include oversize output mimetype so we corrrectly render the
      // "Cannot load output" error state
      output.outputFragment.frontendOutputContents.some(
        (contents) => contents.mimeType === OVERSIZE_OUTPUT_MIMETYPE,
      ),
  );
  return { richOutputs, previewOutputs, errorOutputs };
}
